var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("c-tree-table", {
              attrs: {
                title: "설비/자재 목록",
                parentProperty: "upEquipmentBomCd",
                customID: "equipmentBomCd",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                expandAll: true,
                gridHeight: _vm.grid.height,
              },
              on: { rowClick: _vm.rowClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "설비/자재 정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editable && _vm.deleteable,
                                  expression: "editable && deleteable",
                                },
                              ],
                              attrs: { label: "BOM 추가", icon: "add" },
                              on: { btnClicked: _vm.addData },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.deleteable &&
                                    _vm.isNotFirstRow,
                                  expression:
                                    "editable && deleteable && isNotFirstRow",
                                },
                              ],
                              attrs: {
                                url: _vm.deleteUrl,
                                isSubmit: _vm.isDelete,
                                param: _vm.data,
                                mappingType: "DELETE",
                                label: "삭제",
                                icon: "remove",
                              },
                              on: {
                                beforeAction: _vm.deleteData,
                                btnCallback: _vm.deleteCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.saveable &&
                                    _vm.isNotFirstRow,
                                  expression:
                                    "editable && saveable && isNotFirstRow",
                                },
                              ],
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.data,
                                mappingType: _vm.saveType,
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveData,
                                btnCallback: _vm.saveCallback,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                _vm.dataeditable &&
                                _vm.isNotFirstRow,
                              codeGroupCd: "EQUIPMENT_BOM_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "equipmentBomTypeCd",
                              label: "구분",
                            },
                            model: {
                              value: _vm.data.equipmentBomTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentBomTypeCd", $$v)
                              },
                              expression: "data.equipmentBomTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          !_vm.data.equipmentBomTypeCd
                            ? _c("c-text", {
                                attrs: {
                                  required: true,
                                  editable: false,
                                  label: "설비/자재 명",
                                  name: "equipmentBomName",
                                },
                                model: {
                                  value: _vm.data.equipmentBomName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "equipmentBomName", $$v)
                                  },
                                  expression: "data.equipmentBomName",
                                },
                              })
                            : _vm._e(),
                          _vm.data.equipmentBomTypeCd == "EBTC000001"
                            ? _c("c-text", {
                                attrs: {
                                  required: true,
                                  editable:
                                    _vm.editable &&
                                    _vm.dataeditable &&
                                    _vm.isNotFirstRow,
                                  disabled: !_vm.isEquipEdit,
                                  afterIcon:
                                    _vm.editable &&
                                    _vm.dataeditable &&
                                    _vm.isNotFirstRow
                                      ? [
                                          // { name: 'mode_edit', click: true, callbackName: 'equipEdit', color: '', tooltip: '수기입력' },
                                          {
                                            name: "search",
                                            click: true,
                                            callbackName: "searchEquip",
                                            color: "teal",
                                          },
                                          {
                                            name: "close",
                                            click: true,
                                            callbackName: "removeEquip",
                                            color: "red",
                                          },
                                        ]
                                      : null,
                                  label: "설비/자재 명",
                                  name: "equipmentBomName",
                                },
                                on: {
                                  equipEdit: _vm.equipEdit,
                                  searchEquip: _vm.searchEquip,
                                  removeEquip: _vm.removeEquip,
                                },
                                model: {
                                  value: _vm.data.equipmentBomName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "equipmentBomName", $$v)
                                  },
                                  expression: "data.equipmentBomName",
                                },
                              })
                            : _vm._e(),
                          _vm.data.equipmentBomTypeCd == "EBTC000002"
                            ? _c("c-text", {
                                attrs: {
                                  required: true,
                                  editable:
                                    _vm.editable &&
                                    _vm.dataeditable &&
                                    _vm.isNotFirstRow,
                                  disabled: !_vm.isEquipEdit,
                                  afterIcon:
                                    _vm.editable &&
                                    _vm.dataeditable &&
                                    _vm.isNotFirstRow
                                      ? [
                                          // { name: 'mode_edit', click: true, callbackName: 'equipEdit', color: '', tooltip: '수기입력' },
                                          {
                                            name: "search",
                                            click: true,
                                            callbackName: "searchMaterial",
                                            color: "teal",
                                          },
                                          {
                                            name: "close",
                                            click: true,
                                            callbackName: "removeEquip",
                                            color: "red",
                                          },
                                        ]
                                      : null,
                                  label: "설비/자재 명",
                                  name: "equipmentBomName",
                                },
                                on: {
                                  equipEdit: _vm.equipEdit,
                                  searchMaterial: _vm.searchMaterial,
                                  removeEquip: _vm.removeEquip,
                                },
                                model: {
                                  value: _vm.data.equipmentBomName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "equipmentBomName", $$v)
                                  },
                                  expression: "data.equipmentBomName",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-plant", {
                            attrs: {
                              type: "edit",
                              editable:
                                _vm.editable &&
                                _vm.dataeditable &&
                                _vm.isNotFirstRow,
                              required: true,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-equip-bom", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.param.plantCd,
                              equipmentCd: _vm.param.equipmentCd,
                              editable:
                                _vm.editable &&
                                _vm.dataeditable &&
                                _vm.isNotFirstRow,
                              label: "상위 설비/자재",
                              name: "upEquipmentBomCd",
                            },
                            model: {
                              value: _vm.data.upEquipmentBomCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "upEquipmentBomCd", $$v)
                              },
                              expression: "data.upEquipmentBomCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                _vm.dataeditable &&
                                _vm.isNotFirstRow,
                              label: "개수",
                              name: "unit",
                              type: "number",
                            },
                            model: {
                              value: _vm.data.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "unit", $$v)
                              },
                              expression: "data.unit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: false,
                              codeGroupCd: "MATERIAL_UNIT_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "materialUnitCd",
                              label: "단위",
                            },
                            model: {
                              value: _vm.data.materialUnitCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "materialUnitCd", $$v)
                              },
                              expression: "data.materialUnitCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                _vm.dataeditable &&
                                _vm.isNotFirstRow,
                              label: "항목번호",
                              name: "sortOrder",
                              type: "number",
                            },
                            model: {
                              value: _vm.data.sortOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "sortOrder", $$v)
                              },
                              expression: "data.sortOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "현재 재고량",
                              type: "number",
                              name: "realStock",
                            },
                            model: {
                              value: _vm.data.realStock,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "realStock", $$v)
                              },
                              expression: "data.realStock",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }